import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Books from './components/books/Books';
import './App.css';
import PreviewPage from './components/previewPage/PreviewPage';
// import CardPage from './components/CardPage';
import {Button, Col, ConfigProvider, Layout, Menu, Row, theme, Tooltip} from 'antd';
import {AppstoreOutlined, BulbFilled, QuestionCircleOutlined} from '@ant-design/icons';


const { Header, Content, Footer } = Layout;

{/*  */}

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const toggleTheme = () => {
    setDarkMode((previousValue) => !previousValue);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#722ed1',
        },
        algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,

      }}
    >
      <Layout>
        <Header>
          <div className="demo-logo" />
          <Row>
          <Col span={23}>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={['welcome']}
              items={
                [
                  {
                    label: 'Welcome',
                    key: 'welcome',
                    icon: <AppstoreOutlined />,
                  },
                  { 
                    key: 'about', 
                    label: 'About', 
                    icon: <QuestionCircleOutlined />,
                  },
                ]
              }
            />
            </Col>
            <Col span={1} style={{ textAlign: 'right' }}>
              <Tooltip title="Toggle theme">
                <Button type="primary" shape="circle" onClick={toggleTheme} icon={<BulbFilled />} />
              </Tooltip>
            </Col>
          </Row>
        </Header>
        <Content className={'content-style'} >
          <div className="site-layout-content" >
            <Router>
              <div className="App">
                <Routes>
                  <Route index path="/" element={<PreviewPage />} />
                  <Route index path="/books" element={<Books />} />
                  {/* <Route path="/cards" element={<CardPage />} /> */}
                </Routes>
              </div>
            </Router>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Created by Randyland Innovations LLC</Footer>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
