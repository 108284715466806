import React, {useEffect, useRef, useState} from "react";
import streamSaver from "streamsaver";
import "./Books.css";
import {useSearchParams} from 'react-router-dom';
import {Carousel} from 'react-responsive-carousel';
import config from '../../config';
import {Button, Card, Col, Form, Input, Modal, Row} from "antd";
import TextArea from "antd/es/input/TextArea";

const loadingMessages = [
  'Reticulating splines...',
  'Generating plot twists...',
  'Brewing coffee...',
  'Sharpening pencils...',
  'Polishing prose...',
  'Mixing metaphors...',
  'Plotting revenge...',
  'Baking cookies...',
  'Summoning the muse...',
  'Charging the flux capacitor...',
  'Adjusting margins...',
  'Composing sonnets...',
  'Building characters...',
  'Creating cliffhangers...',
  'Constructing plot arcs...',
  'Drawing storyboards...',
  'Filling ink pots...',
  'Feathering quills...',
  'Dreaming up titles...',
  'Editing manuscripts...',
  'Fact-checking fiction...',
  'Formatting footnotes...',
  'Imagining worlds...',
  'Inspiring ideas...',
  'Juggling adverbs...',
  'Justifying text...',
  'Kneading narratives...',
  'Leaning on the fourth wall...',
  'Minding the macguffins...',
  'Nudging narratives...',
  'Organizing outlines...',
  'Proofreading pages...',
  'Querying quirks...',
  'Revising revisions...',
  'Skimming synopses...',
  'Unfolding cliffhangers...',
  'Vetting verb tenses...',
  'Whittling words...',
  'Xeroxing xanadus...',
  'Yearning for yarns...',
  'Zooming in on zeugmas...',
  'Blowing up balloons...',
  'Curling ribbons...',
  'Dusting bookshelves...',
  'Easing tensions...',
  'Frothing milk...',
  'Growing ideas...',
  'Hiding spoilers...',
  'Illuminating insights...',
  'Jazzing up jargon...',
  'Kicking clichés...',
  'Levitating letters...',
  'Marinating metaphors...',
  'Nibbling on nouns...',
  'Optimizing oxymorons...',
  'Picking plot holes...',
  'Quenching quills...',
  'Rustling pages...',
  'Stirring up subplots...',
  'Tightening tension...',
  'Untangling plot threads...',
  'Venting villains...',
  'Warming up words...',
  'Examining exposition...',
  'Yawning at clichés...',
  'Zesting up dialogues...',
  'Accelerating alliteration...',
  'Balancing book budgets...',
  'Calibrating characters...',
  'Decrypting dialects...',
  'Elevating endings...',
  'Fetching foreshadowing...',
  'Gathering genres...',
  'Honing hooks...',
  'Injecting intrigue...',
  'Juxtaposing jargon...',
  'Kicking out kinks...',
  'Liberating literature...',
  'Modifying motifs...',
  'Neutralizing negations...',
  'Obfuscating objects...',
  'Pacifying protagonists...',
  'Quelling queries...',
  'Resurrecting red herrings...',
  'Soothing syntax...',
  'Taming twists...',
  'Unearthing undertones...',
  'Vivifying verbiage...',
  'Whipping up whimsy...',
  'Extricating expositions...',
  'Yoking yesteryears...',
  'Zapping zeitgeists...',
  'Absorbing atmosphere...',
  'Beautifying backstories...',
  'Catalyzing conflicts...',
  'Dividing dialogue...',
  'Emphasizing emotions...',
  'Finding the narrative flow...',
  'Glorifying grammar...',
  'Highlighting hyperbole...',
  'Illustrating imagery...',
  'Juggling juxtapositions...',
  'Kneading knowledge...',
  'Loosening the leitmotifs...',
  'Mimicking metaphysics...',
  'Nurturing narration...',
  'Oscillating opinions...',
  'Purging punctuation...',
  'Quizzing quality...',
  'Rearranging romance...',
  'Synthesizing similes...',
  'Tackling tropes...',
  'Uncorking understanding...',
  'Validating vernacular...',
  'Whisking wit...',
  'X-raying xenology...',
  'Yearning for yesteryear...',
  'Zoning in on zeniths...',
  'Downloading doughnuts...',
  'Locating the end of the rainbow...',
  'Counting imaginary sheep...',
  'Detangling the plot spaghetti...',
  'Spinning in office chairs...',
  'Constructing metaphysical sandcastles...',
  'Inventing a new punctuation mark...',
  'Procrastinating professionally...',
  'Skipping adverbs...',
  'Arguing with autocorrect...',
  'Outrunning adjectives...',
  'Teaching robots to love...',
  'Critiquing clichés...',
  'Debating the existence of comic sans...',
  'Easing existential ennui...',
  'Feeding the office unicorn...',
  'Unraveling writer’s block...',
  'Bargaining with a stubborn protagonist...',
  'Spellchecking onomatopoeia...',
  'Cracking open a dictionary...',
  'Debugging the hero’s journey...',
  'Sweet talking the muses...',
  'Synthesizing synonyms...',
  'Balancing on the edge of suspense...',
  'Inviting cliffhangers to tea...',
  'Training pet metaphors...',
  'Deploying plot bunnies...',
  'Holding up a metaphorical mirror...',
  'Picking up dramatic irony at the airport...',
  'Vaccinating against adverb abuse...',
  'Unpacking nested narratives...',
  'Planting Chekhov\'s gun...',
  'Finishing that novel, finally...',
  'Dodging dangling modifiers...',
  'Balancing a narrative on one toe...',
  'Oiling the gears of creativity...',
  'Building sandcastles in the plot...',
  'Challenging character stereotypes...',
  'Gilding the prose lily...',
  'Weaving a basket of words...',
  'Staring intensely at a blank page...',
  'Avoiding allegory allergies...',
  'Consulting with Shakespeare...',
  'Running away with the circus...',
  'Polishing plot devices...',
  'Fishing for the right word...',
  'Petting narrative cats...',
  'Sewing seeds of discord...',
  'Juggling with plotlines...',
  'Trimming the narrative hedge...',
  'Walking the exposition dog...',
  'Answering rhetorical questions...',
  'Searching for character arcs in the sofa cushions...',
  'Growing a tree of foreshadowing...',
  'Teaching verbs to swim...',
  'Inventing an eighth day of the week...',
  'Pruning purple prose...',
  'Curing metaphor maladies...',
  'Cleaning the allegory attic...',
  'Waxing poetic about ellipses...',
  'Warming up cold opens...',
  'Wrestling with writer’s block...',
  'Jousting with jargon...',
  'Skating on thin themes...',
  'Tickling the narrative funny bone...',
  'Unleashing a horde of homonyms...',
  'Venting about verboseness...',
  'Whittling a wand of words...',
  'X-raying xanadu...',
  'Yearning for yonder...',
  'Zooming through zeitgeist...',
  'Begging the moon for more time...',
  'Calculating the weight of words...',
  'Decrypting a dialect...',
  'Escaping from the editor’s red pen...',
  'Framing the perfect frame narrative...',
  'Googling a ghostwriter...',
  'Hunting down hyperboles...',
  'Illuminating idioms...',
  'Joking with jesters...',
  'Kicking out kinks...',
  'Leaping off the cliffhanger...',
  'Marching to the beat of a different drummer...',
  'Nibbling on the edge of a narrative...',
  'Outlining in the margins...',
  'Polishing plot pebbles...',
  'Questioning the question mark...',
  'Riding the rising action...',
  'Swinging on a star...',
  'Taking a time-out with Tolstoy...',
  'Unraveling understatements...',
  'Venturing into the verbal vortex...',
  'Whispering sweet nothings to narratives...',
  'X-ing out expositions...',
  'Yelling for yesteryear...',
  'Zooming past the zenith...'
];

const Books = () => {
  console.log('MyComponent re-rendered');

  const [searchParams] = useSearchParams();
  const [isValidSession, setIsValidSession] = useState(false);
  const [coverCarouselIndex, setcoverCarouselIndex] = useState(0);
  const [illustrationCarouselIndex, setIllustrationCarouselIndex] = useState([]);
  const [bookResult, setBookResult] = useState(null);
  const [sessionid, setSessionid] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [mergedShowFormIsValidSession, setMergedShowFormIsValidSession] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingImageIndices, setLoadingImageIndices] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const [bookUpdateInputValue, setBookUpdateInputValue] = useState('');

  const inputRef = useRef(null);

  // const Modal = ({ onClose, children }) => {
  //   return (
  //     <div className="modal-overlay">
  //       <div className="modal">
  //         <button className="modal-close" onClick={onClose}>
  //           X
  //         </button>
  //         {children}
  //       </div>
  //     </div>
  //   );
  // };


  function getRandomMessage() {
    setLoadingMessage(loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);
  }

  useEffect(() => {
    getRandomMessage();
  }, []);

  useEffect(() => {
    getRandomMessage();
  }, [isLoading]);

  useEffect(() => {
    const session = searchParams.get('session');
    validateSession(session); // sets isValidSession state variable
    // Check to see if there is an existing book session already created for this user based on their session cookie
    getBookSession();
  }, [searchParams]);

  useEffect(() => {
    console.log("bookResult changed")
    console.log(bookResult)
    console.log(illustrationCarouselIndex)
    if (bookResult && illustrationCarouselIndex.length < bookResult.pages.length - 1) {
      setIllustrationCarouselIndex(new Array(bookResult.pages.length - 1).fill(0));
    }
  }, [bookResult]); // This will run every time "bookResult" changes
  
  const [bookDetails, setBookDetails] = useState({
    bookType: "",
    childName: "",
    childAge: "",
    childInterests: "",
    childDescription: "",
  });

  // const [bookDetails, setBookDetails] = useState({
  //   bookType: "",
  //   childName: "Andy",
  //   childAge: "",
  //   childInterests: "Golf",
  //   childDescription: "Ugly",
  // });

  // const tempResult = {
  //   title: "My Book",
  //   cover: "Test Cover illustration",
  //   pages: [
  //     {
  //       text: "This is the first page of my book. It is about me, [child name]. I am [child age] years old. I like [child interests]. I am [child description].",
  //       illustration: "Test illustration"
  //     }
  //   ]
  // }

  const handleModalOpen = (index) => {
    setModalIndex(index);
    setIsModalOpen(true);
  };
  
  const handleModalClose = () => {
    setBookUpdateInputValue(null);
    setIsModalOpen(false);
  };

  const handleInputChange = (event) => {
    setBookUpdateInputValue(event.target.value);
  };

  const handleBookUpdateSubmit = async (event) => {
    event.preventDefault();
    console.log("modalIndex" + modalIndex + " bookUpdateInputValue" + bookUpdateInputValue)
    const payload = {
      page_index: modalIndex,
      user_message: bookUpdateInputValue,
    };
    setLoadingImageIndices((prevLoadingImageIndices) => {
      const updatedLoadingImageIndices = new Set(prevLoadingImageIndices);
      updatedLoadingImageIndices.add(modalIndex);
      return updatedLoadingImageIndices;
    });
    try {
      const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/books/"+sessionid, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setBookResult(result);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoadingImageIndices((prevLoadingImageIndices) => {
        const updatedLoadingImageIndices = new Set(prevLoadingImageIndices);
        updatedLoadingImageIndices.delete(modalIndex);
        return updatedLoadingImageIndices;
      });
    }
      handleModalClose();
  };

  const validateSession = async (session) => {
    // Check to see if there is a session cookie already set...
    // If there is no session cookie, check to see if there is a session query parameter
    if (session !== null) {
      // Call the API to validate the session
      try {
        const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/validate_session?sessionId=" + session, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        // If the session is valid, return true
        const result = await response.json();
        if (result.valid) {
          console.log("returning true")
          // set the valid session state to true
          setIsValidSession(true);

          // set the browser cookie for the session id to the session id
          //TODO: set this to a secure cookie and a long life... 1 year?
          document.cookie = "session=" + result.session_id;
          // set the session id in the state
          setSessionid(result.session_id);
        } else {
          console.log("returning false")
          setIsValidSession(false);
        }
      } catch (error) {
        console.error("Error validating session:", error);
        console.log("returning false")
        setIsValidSession(false);
      }
    } else if  (document.cookie.split(';').some((item) => item.trim().startsWith('session=')) && session === null) {
      console.log("Found session cookie")
      setIsValidSession(true);
      return;
    } else {
      console.log("returning false")
      setIsValidSession(false);
    }
    
  };

  const handleIllustrationCarouselChange = (carouselIndex) => (slideIndex) => {
    setIllustrationCarouselIndex((prevIndices) => {
      // Create a new array to avoid mutating the state directly.
      const newIndices = [...prevIndices];
  
      // Update the index of the selected slide for the specified carousel.
      newIndices[carouselIndex] = slideIndex;
  
      return newIndices;
    });
  };

  const getBookSession = async () => {
    // get the session id from the cookie
    const session = document.cookie.split(';').some((item) => item.trim().startsWith('session=')) ? document.cookie.split(';').find((item) => item.trim().startsWith('session=')).split('=')[1] : null;
    console.log("session: " + session)
    // set the session id in the state
    setSessionid(session);

    // if there is a session id, call the API to get the book session
    if (session) {
      try {
        const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/books/" + session, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        // If the session is valid, return true

        // If it's a 200, we have a book session, otherwise it's an error and we shouldnt do anything.
        if (response.status === 200) {
          const result = await response.json();
          console.log("result: " + result)        
          // set the book result to the result
          setBookResult(result);
          // hide the form
          setShowForm(false);
          // Set the form data to the book session data
          setBookDetails((prevDetails) => ({ ...prevDetails, childName: result.name, childDescription: result.description }));
        }
      } catch (error) {
        //TODO: handle error
      }
    }
  }

  const handleChange = (event) => {
    const {name, value} = event.target;
    setBookDetails((prevDetails) => ({...prevDetails, [name]: value}));
  };

  useEffect(() => {
    console.log(bookDetails)
  }, [bookDetails])

  const handleSubmit = async (event) => {
    // event.preventDefault();

    console.log(bookDetails);
    const payload = {
      id: sessionid,
      name: bookDetails.childName,
      // book_type: bookDetails.bookType,
      // child_age: bookDetails.childAge,
      interests: bookDetails.childInterests,
      description: bookDetails.childDescription,
    };
    setIsLoading(true); // Set loading state to true before making the API call
    try {
      const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/books", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setBookResult(result);
        setShowForm(false);

        // Automatically generate the cover illustration image
        if (result.pages[0].illustration) {
          console.log("Generating cover image...")
          await generateImage(result.pages[0].illustration, bookDetails.childName, bookDetails.childDescription, 0);
        }

      } else {
        console.error("Error submitting book details:", response.status, response.statusText);
        // Handle error response from the API here
      }
    } catch (error) {
      console.error("Error submitting book details:", error);
      // Handle network error or any other error here
    } finally {
      setIsLoading(false); // Set loading state to false after the API call has completed
    }
  
  };

  const generatePDF = async () => {
    setIsLoading(true);
    
    // Create a new object with the same structure as the payload sent to the API
    // interface PdfBookClientRequest {
    //   id: string;
    //   coverImageIndex: number;
    //   illustrationImageIndexArray: number[];
    // }
    

    const pdfRequest = {
      id: sessionid,
      coverImageIndex: coverCarouselIndex,
      illustrationImageIndexArray: illustrationCarouselIndex,
    };

    console.log(pdfRequest)
    try {
      const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/pdfs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pdfRequest),
      });

      // If the response is okay, a PDF is generated, and the response should contain the location of the PDF that we can download...
      // Check to see if the response code is 201
      if (response.ok) {
        console.log("PDF generated successfully - " + response.status);
        // Now download the PDF from the location provided in the response
        const pdfResponse = await response.json();
        console.log(pdfResponse);

        try {
          const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/pdfs/" + sessionid + "/" + pdfResponse.pdf, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }          
          });
        
          if (response.ok) {
            console.log("PDF downloaded successfully - " + response.status); 
            // Create a writable stream for the PDF file
            const fileStream = streamSaver.createWriteStream("book.pdf");
            const readableStream = response.body; // Get the ReadableStream from the response

            if (window.WritableStream && readableStream.pipeTo) {
              console.log("modern browser")
              // Use pipeTo if available (modern browsers)
              await readableStream.pipeTo(fileStream);
            } else {
              // Fallback for older browsers
              console.log("fallback")
              const writer = fileStream.getWriter();
              const reader = response.body.getReader();
              const pump = async () => {
                const { done, value } = await reader.read();
                if (done) {
                  await writer.close();
                } else {
                  await writer.write(value);
                  await pump();
                }
              };
              await pump();
            }

            // Update bookresult to include the PDF and set the digital copy flag to true
            setBookResult((prevResult) => ({ ...prevResult, digital_copy_available: true, digital_copy_name: pdfResponse.pdf}));
          } else {
            console.error("Error generating PDF:", response.status, response.statusText);
            // Handle error response from the API here
          }
        }catch (error) {
            console.error("Error generating PDF:", error);
            // Handle network error or any other error here
        }
            
    } else {
      console.error("Error generating PDF:", response.status, response.statusText);
      // Handle error response from the API here
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
    // Handle network error or any other error here
  } finally {
    setIsLoading(false);
  }
  };
  
  const downloadPdf = async () => {
    setIsLoading(true);
  
    try {
      // Replace the URL and payload structure below based on your PDF generation API
      const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/pdfs/" + sessionid + "/" + bookResult.digital_copy_name, {
        method: "GET",
      });
  
      if (response.ok) {
      console.log("got response: " + response)
      // Create a writable stream for the PDF file
      const fileStream = streamSaver.createWriteStream("book.pdf");
      const readableStream = response.body; // Get the ReadableStream from the response

      if (window.WritableStream && readableStream.pipeTo) {
        // Use pipeTo if available (modern browsers)
        await readableStream.pipeTo(fileStream);
      } else {
        // Fallback for older browsers
        const writer = fileStream.getWriter();
        const reader = response.body.getReader();
        const pump = async () => {
          const { done, value } = await reader.read();
          if (done) {
            writer.close();
          } else {
            writer.write(value);
            pump();
          }
        };
        pump();
      }
    } else {
      console.error("Error generating PDF:", response.status, response.statusText);
      // Handle error response from the API here
    }
  } catch (error) {
    console.error("Error generating PDF:", error);
    // Handle network error or any other error here
  } finally {
    setIsLoading(false);
  }
  };
  

  const generateImage = async (prompt_text, name, description, index) => {
    setLoadingImageIndices((prevLoadingImageIndices) => {
      const updatedLoadingImageIndices = new Set(prevLoadingImageIndices);
      updatedLoadingImageIndices.add(index);
      return updatedLoadingImageIndices;
    });

    // setBookResult((prevBookResult) => {
    //     const updatedPages = [...prevBookResult.pages];
    //     updatedPages[index].images = [];
    //     return { ...prevBookResult, pages: updatedPages };
    // });
    
    try {
      const body = {
        id: sessionid,
        prompt_text: `${prompt_text}`,
        name: `${name}`,
        description: `${description}}`,
        page_index: index,
      }

      const response = await fetch(config[process.env.NODE_ENV].apiUrl + "/images", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
  
      if (response.ok) {
        const bookResponse = await response.json();
        setBookResult(bookResponse)
      } else {
        console.error("Error generating image:", response.status, response.statusText);
        // Handle error response from the API here
      }
    } catch (error) {
      console.error("Error generating image:", error);
      // Handle network error or any other error here
    } finally {
      setLoadingImageIndices((prevLoadingImageIndices) => {
        const updatedLoadingImageIndices = new Set(prevLoadingImageIndices);
        updatedLoadingImageIndices.delete(index);
        return updatedLoadingImageIndices;
      });
    }
  };
  
  const coverStatusFormatter = (current, total) => {
    if (loadingImageIndices.has(0)) {
      return( <div className="loader" style={{ display: "inline-block" }}></div>)
    } else {
      return `${current} of ${total}`;
    }
  };

  const pageStatusFormatter = (index) => {
    return (current, total) => {
      if (loadingImageIndices.has(index+1)) {
        return( <div className="loader" style={{ display: "inline-block" }}></div>)
      } else {
        return `${current} of ${total}`;
      }
    }
  }

  const handleBackButtonClick = () => {
    setBookResult(null);
    setShowForm(true);
  };

  useEffect(() => {
    if (showForm && isValidSession) {
      setMergedShowFormIsValidSession(true);
    } else {
      setMergedShowFormIsValidSession(false);
    }
  }, [showForm, isValidSession]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
      <>
        <h1 className={'main-heading'}>mAIk-a-book</h1>
        <h3 className={'common-heading'}>Create a personalized story book where your loved one (or otherwise) is the
          star!</h3>
        {mergedShowFormIsValidSession ? (
            <Row className={'justify-content-center'}>
              <Col xs={24} lg={8}>
                <Card>
                  <Form
                      name="basic"
                      labelCol={{
                        span: 10,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      style={{
                        maxWidth: 600,
                      }}
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={handleSubmit}>

                    <Form.Item name="childName">
                      <div>
                        <label htmlFor="childName">Main Character's Name:</label>
                        <Input
                            rule={[{type: 'text'}]}
                            size={'large'}
                            id="childName"
                            onChange={handleChange}
                            name="childName"
                            placeholder="Enter main character's name"
                        />
                      </div>
                    </Form.Item>
                    <Form.Item name="childDescription">
                      <div>
                        <label htmlFor="childAge">Main Character's Description:</label>
                        <Input
                            size={'large'}
                            id="childDescription"
                            onChange={handleChange}
                            name="childDescription"
                            placeholder="Enter main character's age"
                        />
                      </div>
                    </Form.Item>
                    <Form.Item name="childInterests">
                      <div>
                        <label htmlFor="childInterests">Main Character's interests:</label>
                        <TextArea
                            rule={[{type: 'text'}]}
                            size={'large'}
                            id="childInterests"
                            onChange={handleChange}
                            name="childInterests"
                            placeholder="Enter main character's interests"
                        />
                      </div>
                    </Form.Item>
                    <Button type={'primary'} htmlType={'submit'} size={'large'}>
                      Create Book
                    </Button>
                  </Form>
                </Card>
              </Col>
            </Row>

        ) : (isValidSession && bookResult) ? (
            <Card style={{width: '90%', margin: '4rem auto'}}>
              <div>
                <h2 className={'main-heading'}>{bookResult.title}</h2>
                <h3 className={'common-heading'}>Cover Illustration: {bookResult.pages[0].illustration}</h3>
                {/* display the image and button if the array of images is not empty */}
                <div>
                  <Carousel statusFormatter={coverStatusFormatter} interval={3000} infiniteLoop={true}
                            showStatus={true}
                            showThumbs={true} showArrows={true} onChange={index => setcoverCarouselIndex(index)}>
                    {bookResult.pages[0].images.map((image, index) => (
                        <div key={index}>
                          <img className="generated-image"
                               src={config[process.env.NODE_ENV].apiUrl + "/images/" + sessionid + "/" + image}
                               alt={bookResult.pages[0].illustration}/>
                        </div>
                    ))}
                  </Carousel>
                  <Row className={'d-flex justify-content-center'}>
                    <Col xs={24} lg={12}>
                      <Button className={'w-100'}
                              onClick={() => generateImage(bookResult.pages[0].illustration, bookDetails.childName, bookDetails.childDescription, 0)}
                              disabled={loadingImageIndices.size > 0} type={'primary'} size={'large'}>
                        Another Cover Image Option
                      </Button>
                    </Col>
                  </Row>
                </div>


                <h2 className={'common-heading mt-20'}>Pages:</h2>
                <ul className={'pages-ul'}>
                  {bookResult.pages.slice(1).map((page, index) => (
                      <Card key={index}>
                        <li>
                          <h3 className={'common-heading'}>[Page {index + 1}]: {page.text.split("\\n").map(function (item, index) {
                            return (
                                <p key={index}>{item}</p>
                            )
                          })}
                          </h3>
                          <h3 className={'common-heading'}>Illustration Description: {page.illustration}</h3>
                          {page.images.length !== 0 ? (
                              <div>
                                {/* <img className="generated-image" src={"`${config[process.env.NODE_ENV].apiUrl}/images/"+sessionid + "/" +page.images[0]} alt={`Generated image for page ${index}`} /> */}
                                <Carousel statusFormatter={pageStatusFormatter(index)} interval={3000}
                                          infiniteLoop={true}
                                          showStatus={true} showThumbs={true} showArrows={true}
                                          onChange={handleIllustrationCarouselChange(index)}>
                                  {page.images.map((image, index) => (
                                      <div key={index}>
                                        <img className="generated-image"
                                             src={config[process.env.NODE_ENV].apiUrl + "/images/" + sessionid + "/" + image}
                                             alt={page.illustration}/>
                                      </div>
                                  ))}
                                </Carousel>
                                <Row className={'d-flex justify-content-center mt-20'}>
                                  <Col xs={24} lg={12}>
                                    <Button className={'w-100'}
                                            onClick={() => generateImage(page.illustration, bookDetails.childName, bookDetails.childDescription, index + 1)}
                                            disabled={loadingImageIndices.size > 0} type={'primary'} size={'large'}>
                                      Add Another Image
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                          ) : loadingImageIndices.has(index + 1) ? (
                              <div className="loader" style={{display: "inline-block"}}></div>
                          ) : (

                              <Row className={'d-flex justify-content-center mt-20'}>
                                <Col xs={24} lg={12}>
                                  <Button className={'w-100'}
                                          onClick={() => generateImage(page.illustration, bookDetails.childName, bookDetails.childDescription, index + 1)}
                                          disabled={loadingImageIndices.size > 0} type={'primary'} size={'large'}>
                                    Generate Image
                                  </Button>
                                </Col>
                              </Row>
                          )}
                          <Row className={'d-flex justify-content-center mt-20'}>
                            <Col xs={24} lg={12}> <Button className={'w-100'} type={'primary'} size={'large'}
                                                          onClick={() => handleModalOpen(index + 1)}>Edit Page
                              Details</Button>
                            </Col>
                          </Row>
                        </li>
                      </Card>

                  ))}
                </ul>
                <Button type={'primary'} size={'large'} onClick={handleBackButtonClick}>Back</Button>
              </div>
              <Modal
                  title="Edit the page"
                  open={isModalOpen}
                  closable={true}
                  okText={'Submit'}
                  cancelText={'Close'}
                  onOk={handleBookUpdateSubmit}
                  onCancel={handleCancel}
              >
                <Form>
                  <label>
                    What would you like to change in this page?
                  </label>
                  <Input size={'large'} key="asdf" onChange={handleInputChange}/>
                </Form>
              </Modal>
            </Card>

        ) : (
            <div>
              <h3 style={{color: 'red'}} className={'common-heading'}>Invalid session. Please try again.</h3>
            </div>
        )}
        {bookResult && !bookResult.digital_copy_fullfilled ? (
            <Row className={'d-flex justify-content-center mt-20'}>
              <Col xs={24} lg={12}>
                <Button className={'w-100'}
                        onClick={generatePDF}
                        disabled={loadingImageIndices.size > 0} type={'primary'} size={'large'}>
                  Generate PDF
                </Button>
              </Col>
            </Row>
        ) : bookResult && bookResult.digital_copy_fullfilled ? (
            <Row className={'d-flex justify-content-center mt-20'}>
              <Col xs={24} lg={12}>
                <Button className={'w-100'}
                        onClick={downloadPdf}
                        disabled={loadingImageIndices.size > 0} type={'primary'} size={'large'}>
                  Download your book
                </Button>
              </Col>
            </Row>
        ) : (
            <div></div>
        )}

        {isLoading && (
            <div>
              <h3 className={'common-heading loading-message'}>The AI is generating your personalized story! Hang tight for a minute or two. On average this takes
                about 2 minutes... So just chill...</h3>
              <div className="loader"></div>
              <h3 className={'common-heading'}>{loadingMessage}</h3>
            </div>
        )}

      </>

  );
}

export default Books;
