// src/PreviewPage.js
import React, {useEffect, useState} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import screenshot1 from '../../static/images/01.png';
import screenshot2 from '../../static/images/02.png';
import config from '../../config';
import {Button, Card, Col, Input, Row, Select, Typography} from "antd";
import {CheckCircleOutlined, DollarCircleOutlined} from "@ant-design/icons";
import './PreviewPage.css'

const {Title, Paragraph, Text, Link} = Typography;

const PreviewPage = () => {
    const features = [
        {
            icon: <CheckCircleOutlined/>,
            text: "Create a custom story book for your loved ones where they are the star!",
        },
        {
            icon: <CheckCircleOutlined/>,
            text: "You provide the name, some interests, as well as a short description of your loved one and the AI will generate a 10 page story book complete with a cover image and illustrations.",
        },
        {
            icon: <CheckCircleOutlined/>,
            text: "You can interact with the AI to adjust the story or re-create images as needed (with some limits on retries).",
        },
        {
            icon: <DollarCircleOutlined/>,
            text: "Pay for the digitial copy of your book for only $10.00, or pay $25.00 for a physical copy to be printed and shipped to an address of your choice!",
        },
        {
            icon: <CheckCircleOutlined/>,
            text: "Here are some images of the finished product, as well as what the book creation process looks like:"
        }
    ];

    // Add the required state variables
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const [sessionId, setSessionId] = useState('');

    const handleSessionIdChange = (event) => {
        setSessionId(event.target.value);
    };

    const handleSetSessionId = () => {
        //TODO: Validate the session ID
        document.cookie = "session=" + sessionId;
        window.location.href = '/books';
    };

    useEffect(() => {
        console.log(selectedOption1)
    }, [selectedOption1])

    // Add a function to check if all the dropdowns have been selected
    const areAllOptionsSelected = () => {
        return selectedOption1 && selectedOption2; // Add more conditions as needed
    };

    // Add a function to display the example book's output based on the selected options
    const renderExampleBookOutput = () => {
        if (!areAllOptionsSelected()) return null;
        return (
            <div>
                <h3>Example Book Output</h3>
                {/* Render the example book output based on the selected options */}
            </div>
        );
    };

    const redirectToCheckout = (url) => {
        const shouldRedirect = window.confirm('You will be redirected to the Stripe checkout page. Once you submit payment, you will be redirected back to this page where you can create and customize your book. Continue?');

        if (shouldRedirect) {
            window.location.href = url;
        }
    };

    const handleChangeCharacterName = (value) => {
        setSelectedOption1(value)
    };

    const handleChangeCharacterInterest = (value) => {
        setSelectedOption2(value)
    };


    return (
        <>
            <section>
                <h1 className={'main-heading'}>Welcome to MAIk-A-Book</h1>
                <div>
                    <Card className={'card-header'}>
                        <div>
                            {
                                features.map((data, index) => (
                                    <React.Fragment key={index}>
                                        <h3>
                                            <span className="custom-bullet-icon mr-10">{data.icon}</span>
                                            <span>{data.text}</span>
                                        </h3>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </Card>
                </div>
            </section>
            <section>
                <Card className={'card-main'}>
                    <div style={{margin: '0 auto'}}>
                        <Carousel autoPlay={true}
                                  interval={3000}
                                  infiniteLoop={true}
                                  showStatus={false}
                                  showThumbs={false}
                                  showArrows={true}>
                            <div>
                                <img src={screenshot1} alt="Image 1..."/>
                                <p className="legend">Screenshot 1</p>
                            </div>
                            <div>
                                <img src={screenshot2} alt="Image 2..."/>
                                <p className="legend">Screenshot 2</p>
                            </div>
                        </Carousel>
                    </div>
                    <div style={{width: '85%', margin: '0 auto'}}>
                        <div>
                            <Row>
                                <Col span={24}>
                                    <h2>Example AI Generated books</h2>
                                    <p>Here are some example inputs that have been fed to the AI and the resultant book
                                        text and
                                        images.
                                        Mix and match settings all you want!</p>
                                </Col>
                                <Col span={24}>
                                    <label>Character Name:</label>
                                    <Select
                                        size={'large'}
                                        defaultValue={''}
                                        className={'select-custom'}
                                        onChange={handleChangeCharacterName}
                                        options={[
                                            {value: '', label: '--Select--'},
                                            {value: 'example1', label: 'Andrew'},
                                            {value: 'example2', label: 'Marissa'},
                                            {value: 'example3', label: 'Addie'},
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <label>Character Interests:</label>
                                    <Select
                                        size={'large'}
                                        defaultValue={''}
                                        onChange={handleChangeCharacterInterest}
                                        className={'select-custom'}
                                        options={[
                                            {value: '', label: '--Select--'},
                                            {
                                                value: 'example1',
                                                label: 'Golf, Video Games, Web Development, Artificial Intelligence'
                                            },
                                            {
                                                value: 'example2',
                                                label: 'Hiking, Purple Things, Spending Time with Family'
                                            },
                                            {
                                                value: 'example3',
                                                label: 'Eating, Pooping, Being Cute, Playing with Friends'
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </div>
                        {renderExampleBookOutput()}
                        <Card className={'custom-book'}>
                            <div>
                                <h2 className={'custom-book--heading'}>Get your own custom book!</h2>
                                <Row>
                                    <Col>
                                        <h3>
                                            <span className="custom-bullet-icon mr-10"><CheckCircleOutlined/></span>
                                            <span>To get started on creating your own custom book, select one of the two options below. You will be redirected to Stripe Pay to checkout.</span>
                                        </h3>
                                    </Col>
                                    <Col>
                                        <h3>
                                            <span className="custom-bullet-icon mr-10"><DollarCircleOutlined/></span>
                                            <span>Please ensure the address you enter on Stripe Pay is the destination address of where you'd like your book shipped (if you're paying for a physical copy).</span>
                                        </h3>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <Card className={'options'}>
                            <h2 className={'option--heading'}>Option 1: Digital Copy</h2>
                            <Row>
                                <Col span={24}>
                                    <h2>
                                        <span className="custom-bullet-icon mr-10"><DollarCircleOutlined/></span>
                                        <span>Get a digital copy (PDF) of your book for only $10.00 + tax!</span>
                                    </h2>
                                </Col>
                                <Col span={24}>
                                    <Row justify={'center'}>
                                        <Col span={24}>
                                            <h3 className={'common-heading'}>
                                                <span>Click the button below to get started!</span>
                                            </h3>
                                        </Col>
                                        <Col className={'mt-10'}>
                                            <Button type="primary" size={'large'} shape={'round'}
                                                    onClick={() => redirectToCheckout(config[process.env.NODE_ENV].digitalPayLink)}>
                                                Checkout
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                        <Card className={'options'}>
                            <h2 className={'option--heading'}>Option 2: Physical + Digital Copy</h2>
                            <Row>
                                <Col span={24}>
                                    <h2>
                                        <span className="custom-bullet-icon mr-10"><DollarCircleOutlined/></span>
                                        <span>Get both a physical copy of your book, as well as a digital copy for only $25.00 + tax!</span>
                                    </h2>
                                </Col>
                                <Col span={24}>
                                    <Row justify={'center'}>
                                        <Col span={24}>
                                            <h3 className={'common-heading'}>
                                                <span>Click the button below to get started!</span>
                                            </h3>
                                        </Col>
                                        <Col className={'mt-10'}>
                                            <Button type="primary" size={'large'} shape={'round'}
                                                    onClick={() => redirectToCheckout(config[process.env.NODE_ENV].digitalPayLink)}>
                                                Checkout
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Card>
                        <Card>
                            <h2 className={'option--heading confirmation-code-heading'}>Have a previous confirmation
                                number and are looking for
                                your book?</h2>
                            <Row>
                                <Col span={24}>
                                    <label>Enter your confirmation number and hit submit!:</label>
                                    <Input rootClassName={'mt-10'} onChange={handleSessionIdChange}
                                           placeholder="Basic usage" size={'large'}/>
                                </Col>
                                <Col className={'d-flex justify-content-center w-100 mt-20'}>
                                    <Button type="primary" size={'large'} shape={'round'}
                                            onClick={handleSetSessionId}>
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Card>
            </section>
        </>
    );
};

export default PreviewPage;
 