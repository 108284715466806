const config = {
    development: {
    //   apiUrl: 'http://localhost:8787',
        apiUrl: 'https://maikabook-api.andyguck9170.workers.dev',
        digitalPayLink: 'https://buy.stripe.com/test_eVabJD1Z3aoUgWQ000',
        physicalPayLink: 'https://buy.stripe.com/test_bIY3d747b0Ok0XSdQR',
    },
    production: {
        apiUrl: 'https://maikabook-api.andyguck9170.workers.dev',
        digitalPayLink: 'https://buy.stripe.com/test_bIY8xrfPT8gM7mgdQS',
        physicalPayLink: 'https://buy.stripe.com/test_dR6dRLgTX54A0XSfZ1',
    },
  };
  
  export default config;